
import api from '@/config/api';
import {defineComponent, PropType} from "@vue/runtime-core";
import {IProperties, IFieldProperty} from '@/config/apiTypes';
import {formatCashNumber, getUrl} from '@/utils/functions';

const area_privativa: number = 135;
const area_terreno: number = 133;
const area_total: number = 134;

interface fieldsShowType {
  name: string;
  icon: string;
  code: number;
  slug?: string;
}

interface DataTypes {
  fieldsToShow: fieldsShowType[];
  slug: string;
  tableBairros: Array<string>;
}

export default defineComponent({
  setup() {
    return {formatCashNumber};
  },
  props: {
    property: {
      type: Object as PropType<IProperties>,
      required: true
    }
  },
  emits: ['open:modal'],
  data(): DataTypes {
    return {
      fieldsToShow: [
        {name: "Quartos", icon: "bed", code: 153},
        {name: "Banheiros", icon: "bath", code: 136},
        {name: "Área", icon: "arrows-alt", code: area_terreno, slug: "m²"}, //Área do terreno
        {name: "Área", icon: "arrows-alt", code: area_total, slug: "m²"}, //Área total
        {name: "Área", icon: "arrows-alt", code: area_privativa, slug: "m²"} //Área privativa
      ],
      slug: this.getSlug(),
      tableBairros: []
    }
  },
  mounted() {
    this.getTableBairros();
  },
  methods: {
    // getProposal(){
    //     api
    //     .get('auth/proposal')
    //     .then(ref => {
    //         console.log(ref);
    //     });
    // },
    getUrl,
    formatPrice(value: number) {
      let val = (value / 1).toFixed(2).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },
    getQuartos(fields: IFieldProperty[]) {
      let quarto = fields.find(item => item.pivot.CodigoCampo == 153);
      if (typeof quarto !== "undefined") {
        return quarto.pivot.Valor + " quartos, ";
      }
    },
    getMetragem(fields: IFieldProperty[]) {
      let metragem;
      let privativa = fields.find(v => v.pivot.CodigoCampo == area_privativa);
      let total = fields.find(v => v.pivot.CodigoCampo == area_total);
      let terreno = fields.find(v => v.pivot.CodigoCampo == area_terreno);

      if (privativa)
        metragem = privativa;
      else if (total)
        metragem = total;
      else if (terreno)
        metragem = terreno;
      if (typeof metragem !== "undefined") {
        return metragem.pivot.Valor + "m²";
      }
    },
    getAvaiableFields(fields: IFieldProperty[]) {
      const AreaFieldArray = [area_terreno, area_total, area_privativa];
      let AreaField: number;
      let bathCodes = [136,137,163];

      //Definindo prioridade de qual campo de Área deve ser mostrado
      if (fields.find(v => v.pivot.CodigoCampo == area_privativa))
        AreaField = area_privativa;
      else if (fields.find(v => v.pivot.CodigoCampo == area_total))
        AreaField = area_total;
      else if (fields.find(v => v.pivot.CodigoCampo == area_terreno))
        AreaField = area_terreno;
      return this.fieldsToShow.map(show => {
        if (show.name != 'Banheiros') {
            const field = fields.find(v => v.pivot.CodigoCampo == show.code);
            if (field && AreaFieldArray.includes(show.code) && AreaField == show.code) {
              return {...show, valor: show?.slug ? field.pivot.Valor + show.slug : field.pivot.Valor}
            } else if (field && !AreaFieldArray.includes(show.code)) {
              return {...show, valor: show?.slug ? field.pivot.Valor + show.slug : field.pivot.Valor}
            }
        } else {
            const bathFields = fields.filter(f => bathCodes.includes(f.pivot.CodigoCampo));
            const value = bathFields.reduce((sum, field) => {
                return sum + parseInt(field.pivot.Valor);
            }, 0);
            return {...show, valor: show?.slug ? value + show.slug : value}
        }
      }).filter(Boolean);
    },
    capitalize(text: string): string {
      try {
        const textLowed = text.toLowerCase();
        return textLowed.split(' ').map(word => {
          return word.charAt(0).toUpperCase() + word.slice(1);
        }).join(' ');
      } catch (error) {
        return text;
      }
    },
    generateSlug(title: string): string {
      return title.normalize('NFC').replaceAll(/\W{1,}/g, '-').toLowerCase();
    },
    getSlug() {
      const property = this.$props.property as IProperties;
      return property.URLAmigavel || this.generateSlug(property.Titulo)
    },
    emitOpenModal(id: number) {
      try {
        const route = {name: 'imovel', params: {id, slug: this.slug}};
        const link = window.location.origin + this.$router.resolve(route).fullPath;
        this.$emit('open:modal', link);
      } catch (e) {
        console.log(e);
      }
    },
    getTableBairros() {
      api
          .get('districts')
          .then(res => {
            this.tableBairros = res.data.tableBairros;
          })
          .catch(err => {
            console.log(err)
          })
    },
    getBairroPai(bairro: string) {
      //Obtendo bairro pai, caso houver
      this.tableBairros.forEach((value: any) => {
        if (value.bairro == bairro && value.id_bairro_pai) {
          this.tableBairros.forEach((val: any) => {
            if (val.id == value.id_bairro_pai)
              bairro = val.bairro; //Substitui pelo bairro pai
          });
        }
      });

      return bairro;
    }
  }
});
